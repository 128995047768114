import { ChecklistType, DocumentType } from '@innedit/innedit-type';
import { ChecklistData } from 'packages/innedit';
import React, { FC, useEffect, useState } from 'react';

import { ListItemProps } from '~/containers/Espace/List/props';
import IconPlus from '~/icons/Plus';

import ListItem from './index';

const ListItemChecklist: FC<ListItemProps<ChecklistType, ChecklistData>> = ({
  className,
  docId,
  index,
  onClick,
  model,
}) => {
  const [doc, setDoc] = useState<DocumentType<ChecklistType>>();

  useEffect(() => {
    let isMounted = true;
    const unsub = model.watchById(docId, document => {
      if (isMounted) {
        setDoc(document);
      }
    });

    return () => {
      isMounted = false;
      if (unsub) {
        unsub();
      }
    };
  }, [docId, model]);

  if (!doc) {
    return null;
  }

  return (
    <ListItem
      actions={[
        {
          icon: IconPlus,
          label: 'Ajouter une sous-liste',
          to: `/espaces/${doc.espaceId}/tasks/checklists/create?parent=${docId}`,
        },
      ]}
      className={className}
      displayActionId={false}
      doc={doc}
      index={index}
      label={doc.label}
      onClick={onClick}
    />
  );
};

export default ListItemChecklist;
